import { Link, useStaticQuery, graphql } from "gatsby"
/** @jsx jsx */
import { Flex, jsx, Box } from "theme-ui"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { navigate } from "gatsby"
//import useAuth from "../hooks/useAuth"
import Headroom from "react-headroom"
import NavDropdown from "./navDropdown"
import NavMenuItem from "./navMenuItem"
import { useCurrentUser, useDispatchCurrentUser } from "./CurrentUser"
import { callApi } from "../utils"

const Header = ({ siteTitle, headerType }) => {
  // const { logout, isAuthenticated } = useAuth()

  const currentUser = useCurrentUser()
  const dispatch = useDispatchCurrentUser()

  const handleLogout = async e => {
    e.preventDefault()
    await callApi("/logout", "POST")
    dispatch({ type: "LOGOUT" })
    navigate("/login")
  }

  // const [dropdown, setDropdown] = useState(false)
  // const onMouseEnter = () => {
  //   if (window.innerWidth < 960) {
  //     setDropdown(false)
  //   } else {
  //     setDropdown(true)
  //   }
  // }

  // const onMouseLeave = () => {
  //   if (window.innerWidth < 960) {
  //     setDropdown(false)
  //   } else {
  //     setDropdown(false)
  //   }
  // }
  //static image query
  const data = useStaticQuery(graphql`
    {
      strapiNavigationHeader {
        rootNav1 {
          link_active
          link_text
          link_url
          id
        }
        subnav1 {
          event {
            slug
          }
          strapi_component
          link_text
          link_url
          news_item {
            slug
          }
          page {
            slug
            nav_parent
          }
        }

        rootNav2 {
          link_active
          link_text
          link_url
          id
        }
        subnav2 {
          event {
            slug
          }
          strapi_component
          link_text
          link_url
          news_item {
            slug
          }
          page {
            slug
            nav_parent
          }
        }

        rootNav3 {
          link_active
          link_text
          link_url
          id
        }
        subnav3 {
          event {
            slug
          }
          strapi_component
          link_text
          link_url
          news_item {
            slug
          }
          page {
            slug
            nav_parent
          }
        }

        rootNav4 {
          link_active
          link_text
          link_url
          id
        }
        subnav4 {
          event {
            slug
          }
          strapi_component
          link_text
          link_url
          news_item {
            slug
          }
          page {
            slug
            nav_parent
          }
        }

        rootNav5 {
          link_active
          link_text
          link_url
          id
        }
        subnav5 {
          event {
            slug
          }
          strapi_component
          link_text
          link_url
          news_item {
            slug
          }
          page {
            slug
            nav_parent
          }
        }

        rootNav6 {
          link_active
          link_text
          link_url
          id
        }
        subnav6 {
          event {
            slug
          }
          strapi_component
          link_text
          link_url
          news_item {
            slug
          }
          page {
            slug
            nav_parent
          }
        }

        rootNav7 {
          link_active
          link_text
          link_url
          id
        }
        subnav7 {
          event {
            slug
          }
          strapi_component
          link_text
          link_url
          news_item {
            slug
          }
          page {
            slug
            nav_parent
          }
        }
      }
      logoHeader: file(
        relativePath: { eq: "masterclass-logo-white-no-square.svg" }
      ) {
        publicURL
      }
    }
  `)

  //hamburger state
  const [hamburger, setHamburger] = useState(false)
  const handleHamburgerClick = () => {
    setHamburger(!hamburger)
  }
  return (
    <header
      sx={{
        position: "relative",
        zIndex: 10,
        "& .headroom--pinned": {
          position: "relative",
          background: "rgba(0, 0, 0, 0.65)", //#383838
          zIndex: 5,
        },

        "& .headroom--unfixed": {
          position: "relative",
          background: "transparent",
          zIndex: 1,
        },
        "&.blockColor .headroom--unfixed": {
          background:
            "linear-gradient(90deg, rgba(244,5,88,1) 0%, rgba(226,30,54,1) 100%)",
          transform: "rotate(-3deg) skew(-3deg) !important",
          py: 4,
          mt: -4,
          "& > div": {
            transform: "rotate(3deg) skew(3deg)",
          },
        },

        //hamburger button
        "& .hamburger": {
          padding: "20px 15px",
          display: ["flex", "flex", "none"],

          cursor: "pointer",
          transitionProperty: "opacity, filter",
          transitionDuration: "0.15s",
          transitionTimingFunction: "linear",
          font: "inherit",
          color: "inherit",
          textTransform: "none",
          backgroundColor: "transparent",
          border: "0",
          //margin: "1em 0 0 0",
          overflow: "visible",
        },
        "& .hamburger:hover": {
          opacity: 0.7,
        },
        "& .hamburger.is-active:hover": {
          opacity: 0.7,
        },
        ".hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner::before, .hamburger.is-active .hamburger-inner::after": {
          backgroundColor: "#fff",
        },

        "& .hamburger-box": {
          width: "40px",
          height: "24px",
          display: "inline-block",
          position: "relative",
        },

        "& .hamburger-inner": {
          display: "block",
          top: "50%",
          marginTop: "-2px",
        },

        "& .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after": {
          width: "40px",
          height: "4px",
          backgroundColor: "#fff",
          borderRadius: "4px",
          position: "absolute",
          transitionProperty: "transform",
          transitionDuration: "0.15s",
          transitionTimingFunction: "ease",
        },
        "& .hamburger-inner::before": {
          width: "40px",
          height: "4px",
          backgroundColor: "#fff",
          borderRadius: "4px",
          position: "absolute",
          transitionProperty: "transform",
          transitionDuration: "0.15s",
          transitionTimingFunction: "ease",
        },
        "& .hamburger-inner::after": {
          width: "40px",
          height: "4px",
          backgroundColor: "#fff",
          borderRadius: "4px",
          position: "absolute",
          transitionProperty: "transform",
          transitionDuration: "0.15s",
          transitionTimingFunction: "ease",
        },

        ".hamburger-inner::before, .hamburger-inner::after": {
          content: '""',
          display: "block",
        },
        "& .hamburger-inner::before": {
          top: "-10px",
        },
        "& .hamburger-inner::after": {
          bottom: "-10px",
        },
        //hamburber spin https://jonsuh.com/hamburgers/

        ".hamburger--spin .hamburger-inner": {
          transitionDuration: "0.22s",
          transitionTimingFunction: "cubic-bezier(0.55, 0.055, 0.675, 0.19)",
        },
        ".hamburger--spin .hamburger-inner::before": {
          transition: "top 0.1s 0.25s ease-in, opacity 0.1s ease-in",
        },
        ".hamburger--spin .hamburger-inner::after": {
          transition:
            "bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19)",
        },

        ".hamburger--spin.is-active .hamburger-inner": {
          transform: "rotate(225deg)",
          transitionDelay: "0.12s,",
          transitionTimingFunction: "cubic-bezier(0.215, 0.61, 0.355, 1)",
        },
        ".hamburger--spin.is-active .hamburger-inner::before": {
          top: 0,
          opacity: 0,
          transition: "top 0.1s ease-out, opacity 0.1s 0.12s ease-out",
        },
        ".hamburger--spin.is-active .hamburger-inner::after": {
          bottom: 0,
          transform: "rotate(-90deg)",
          transition:
            "bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1)",
        },
      }}
      className={`header ${headerType}`}
    >
      <nav
        aria-label="login to members area"
        sx={{
          // display: "flex",
          // justifyContent: "flex-end",
          position: "absolute",
          top: 0,
          right: 0,
          zIndex: 20, //so it can be reached
          a: {
            bg: "accent",
            color: "white",
            px: 3,
            py: 2,
            display: "inline-block",
          },
        }}
      >
        {currentUser.isAuthenticated ? (
          <Link to="/app" className="text-white">
            Members Area
          </Link>
        ) : (
          <Link to="/login" className="text-white">
            Login / Sign-up
          </Link>
        )}
      </nav>
      <Headroom
        wrapperStyle={{ position: "relative" }}
        sx={{
          zIndex: 10,
        }}
      >
        <div
          className={`headerContainer ${hamburger ? "menuOpen" : ""}`}
          sx={{
            "&.menuOpen": {
              background: [
                "linear-gradient(90deg, rgba(244,5,88,1) 0%, rgba(226,30,54,1) 100%)",
                null,
                "transparent",
              ],
            },
          }}
        >
          <Box
            className="headerContainerInner"
            sx={{
              justifyContent: "space-between",
              flexWrap: "wrap",
              alignItems: "center",
              px: [2, 4, 0],
              //pt: "10px",
              display: ["block", null, "flex"],
              //between 1020px and 820px to prevent menu dropping onto second line make logo central
              "@media screen and (max-width: 63.75em) and (min-width: 52em)": {
                //bg: "pink",
                display: "block",
              },
            }}
          >
            <div
              sx={{
                width: ["100%", "auto", null],
                display: ["flex", "flex", null],
                justifyContent: ["space-between", null, "initial"],
                alignItems: ["center", null, "initial"],
                "@media screen and (max-width: 63.75em) and (min-width: 52em)": {
                  justifyContent: "center",
                },
              }}
            >
              <Box>
                <h1 sx={{ mt: [0, "15px "], ml: [2, 0, 3] }}>
                  <Link
                    to="/"
                    style={{
                      color: `white`,
                      textDecoration: `none`,
                    }}
                    aria-label="Homepage"
                  >
                    <img
                      src={data.logoHeader.publicURL}
                      alt="Masterclass logo"
                      sx={{
                        width: ["130px", "150px"],
                        "@media screen and (max-width: 63.75em) and (min-width: 52em)": {
                          width: "130px",
                        },
                      }}
                    />
                  </Link>
                </h1>
              </Box>
              <div
                sx={{
                  width: ["50%", null, "auto"],
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-start",
                }}
              >
                <button
                  className={`hamburger hamburger--spin ${
                    hamburger ? `is-active` : `is-closed`
                  }`}
                  type="button"
                  aria-label="Main Menu"
                  onClick={handleHamburgerClick}
                >
                  <span className="visuallyhidden">Main Menu</span>
                  <span className="hamburger-box">
                    <span className="hamburger-inner"></span>
                  </span>
                </button>
              </div>
            </div>

            <nav
              aria-label="Masterclass Menu"
              className={`${hamburger ? `is-active` : ``}`}
              sx={{
                position: ["absolute", null, "relative"],
                left: ["-100%", null, "auto"],
                background: [
                  "linear-gradient(90deg, rgba(244,5,88,1) 0%, rgba(226,30,54,1) 100%)",
                  null,
                  "transparent",
                ],
                height: ["100vh", "100vh", "auto"],
                width: ["100%", null, "auto"],
                "& ul": { pl: 0 },
                "& ul li": {
                  display: ["block", "block", "inline-block"],
                  position: "relative",
                  mb: [2, null, 0],

                  "& a, & span.no-link": {
                    px: [3, 3, 3],
                    py: [0, 0, 3],
                    color: "white",
                    fontSize: 4,
                    textDecoration: "none",
                  },
                  "& ul.dropdown": {
                    position: ["relative", null, "absolute"],
                    left: ["auto", null, "50%"],
                    transform: ["none", null, "translate(-50%, 0)"],
                    width: ["auto", null, "200px"],
                    pl: [2, null, 0],
                    top: [0, null, "50px"],
                    bg: ["rgba(0, 0, 0, 0.35)", null, "rgba(0, 0, 0, 0.65)"],
                    "@media screen and (min-width: 64em)": {
                      "&:before": {
                        position: "absolute",
                        left: "50%",
                        transform: "translate(-50%, 0)",
                        borderStyle: "solid",
                        borderWidth: "0 10px 10px 10px",
                        borderColor: "transparent transparent red transparent",
                        content: '""',
                        display: "block",
                        height: 0,
                        top: "-10px",
                        width: 0,
                      },
                    },

                    "& > li": {
                      px: 3,
                      py: 2,
                      lineHeight: 1.1,
                      "& a ": {
                        padding: 0,
                        fontSize: [4, null, 3],
                        "&:hover, &:focus": {
                          color: "red",
                        },
                      },
                    },
                  },
                },
                "&.is-active": {
                  left: ["0", null, "auto"],
                },
                "@media screen and (max-width: 63.75em) and (min-width: 53.5em)": {
                  display: "flex",
                  justifyContent: "center",
                  mt: "-50px",
                },
                "@media screen and (max-width: 53.5em) and (min-width: 52em)": {
                  display: "flex",
                  justifyContent: "center",
                  mt: "-50px",
                  "& ul li a, & ul li span.no-link": {
                    px: "10px",
                  },
                },
              }}
            >
              <ul>
                <NavMenuItem
                  link_active={data.strapiNavigationHeader.rootNav1.link_active}
                  link_url={data.strapiNavigationHeader.rootNav1.link_url}
                  link_text={data.strapiNavigationHeader.rootNav1.link_text}
                  dropdownMenu={data.strapiNavigationHeader.subnav1}
                />

                <NavMenuItem
                  link_active={data.strapiNavigationHeader.rootNav2.link_active}
                  link_url={data.strapiNavigationHeader.rootNav2.link_url}
                  link_text={data.strapiNavigationHeader.rootNav2.link_text}
                  dropdownMenu={data.strapiNavigationHeader.subnav2}
                />
                <NavMenuItem
                  link_active={data.strapiNavigationHeader.rootNav3.link_active}
                  link_url={data.strapiNavigationHeader.rootNav3.link_url}
                  link_text={data.strapiNavigationHeader.rootNav3.link_text}
                  dropdownMenuHardCodedItems={[
                    {
                      link_text: "Masterclasses",
                      link_url: "/whatson/masterclasses",
                    },
                    {
                      link_text: "Coffee With Creatives",
                      link_url: "/whatson/coffee-with-creatives",
                    },
                    {
                      link_text: "Script Sessions",
                      link_url: "/whatson/script-sessions",
                    },
                    {
                      link_text: "Pitch Your Play",
                      link_url: "/whatson/pitch-your-play",
                    },
                  ]}
                  dropdownMenu={data.strapiNavigationHeader.subnav3}
                />

                <NavMenuItem
                  link_active={data.strapiNavigationHeader.rootNav4.link_active}
                  link_url={data.strapiNavigationHeader.rootNav4.link_url}
                  link_text={data.strapiNavigationHeader.rootNav4.link_text}
                  dropdownMenu={data.strapiNavigationHeader.subnav4}
                />
                <NavMenuItem
                  link_active={data.strapiNavigationHeader.rootNav5.link_active}
                  link_url={data.strapiNavigationHeader.rootNav5.link_url}
                  link_text={data.strapiNavigationHeader.rootNav5.link_text}
                  dropdownMenu={data.strapiNavigationHeader.subnav5}
                />
                <NavMenuItem
                  link_active={data.strapiNavigationHeader.rootNav6.link_active}
                  link_url={data.strapiNavigationHeader.rootNav6.link_url}
                  link_text={data.strapiNavigationHeader.rootNav6.link_text}
                  dropdownMenu={data.strapiNavigationHeader.subnav6}
                />

                <NavMenuItem
                  link_active={data.strapiNavigationHeader.rootNav7.link_active}
                  link_url={data.strapiNavigationHeader.rootNav7.link_url}
                  link_text={data.strapiNavigationHeader.rootNav7.link_text}
                  dropdownMenu={data.strapiNavigationHeader.subnav7}
                />

                {/* <li>
                  {isAuthenticated ? (
                    <a onClick={handleLogout} className="text-white" href="/">
                      Logout
                    </a>
                  ) : (
                    <Link to="/login-popup" className="text-white">
                      Members Area
                    </Link>
                  )}
                </li> */}
              </ul>
            </nav>
          </Box>
        </div>
      </Headroom>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
