import React from "react"
//import DOMPurify from "dompurify"
import sanitizeHtml from "sanitize-html"
import marked from "marked"
import ReactMarkdown from "react-markdown"
import rehypeRaw from 'rehype-raw'
import rehypeSanitize from 'rehype-sanitize'

const SanitizedHTMLBodyCopy = ({content, theClass="bodyCopy"}) => {
  // const bodyCopy = marked(props.content)

  // const sanitizedCopy = sanitizeHtml(bodyCopy, {
  //   allowedTags: sanitizeHtml.defaults.allowedTags.concat(["img"]),
  //   allowedAttributes: {
  //     a: ["href", "name", "target"],
  //     img: ["src", "alt"],
  //   },
  // })
  return (
    // <div
    //   className="bodyCopy"
    //   dangerouslySetInnerHTML={{ __html: sanitizedCopy }}
    // />
    <ReactMarkdown
      
      transformImageUri={uri =>
        uri.startsWith("http") ? uri : `${process.env.GATSBY_API_URL}${uri}`
      }
      rehypePlugins={[rehypeRaw, rehypeSanitize]}
      // escapeHtml={false}
      // allowedTypes={['paragraph', 'text', 'heading', 'link', 'strong', 'emphasis', 'break', 'virtualHtml']}
      className={theClass}
      children={content}
    />
    
    //Test for rehype having html+md mixed, currently not working
    // <ReactMarkdown rehypePlugins={[rehypeRaw]}>{`
    
    // <div class="note">

    // Some *emphasis* and <strong>strong</strong>!
    
    // </div>`
    
    // }</ReactMarkdown>
  )
}

export default SanitizedHTMLBodyCopy
