/** @jsx jsx */
import { Flex, jsx, Button } from "theme-ui"
import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import NavDropdown from "./navDropdown"

const NavMenuItem = ({
  link_url,
  link_text,
  link_active,
  dropdownMenu,
  dropdownMenuHardCodedItems,
}) => {
  const [dropdown, setDropdown] = useState(false)
  const onMouseEnter = () => {
    if (window.innerWidth < 831) {
      //was 960
      setDropdown(false)
    } else {
      setDropdown(true)
    }
  }

  const onMouseLeave = () => {
    if (window.innerWidth < 831) {
      setDropdown(false)
    } else {
      setDropdown(false)
    }
  }
  //for clicking menu open on touch
  const showMenu = () => {
    setDropdown(!dropdown)
  }

  let dropdownNeeded = false
  if (dropdownMenu.length >= 1) {
    dropdownNeeded = true
  }
  if (dropdownMenuHardCodedItems !== undefined) {
    dropdownNeeded = true
  }
  //console.log(dropdownNeeded)

  return (
    <li
      className={`navMenuItem ${dropdownNeeded ? "caret" : ""}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      sx={{
        "& > span": {
          width: ["80%", "90%", "auto"],
          display: "inline-block",
        },
        "& > a": {
          width: ["80%", "90%", "auto"],
          display: "inline-block",
        },
        "& button": {
          display: ["inline-block", null, "none"],
          transform: "rotate(90deg)",
          px: 2,
          py: 1,
          ml: 2,
          mt: 0,
        },
        "ul.dropdown": {
          //display: ["block", null, "none"],
          display: "none",
          "&.active": {
            display: "block",
          },
        },
        "&:focus-within ul.dropdown": {
          display: "block",
        },
      }}
    >
      {link_active ? (
        <Link to={link_url}>{link_text}</Link>
      ) : (
        <span className="no-link" role="button" tabIndex={0}>
          {link_text}
        </span>
      )}

      {dropdownNeeded && (
        <>
          <Button onClick={showMenu} variant="inverted">
            {">"}
          </Button>
          <NavDropdown
            submenu={dropdownMenu}
            hardCodedMenuItems={dropdownMenuHardCodedItems}
            className={dropdown ? "active" : ""}
          />
        </>
      )}
    </li>
  )
}
export default NavMenuItem
