import React, { useState, useRef } from "react"
/** @jsx jsx */
import { Box, Flex, jsx, Text, Input, Button } from "theme-ui"
//import addToMailchimp from "gatsby-plugin-mailchimp"
import { ToastContainer, toast } from "react-toastify"

const FooterFormLink = props => {
  //   const [formError, setFormError] = useState("")
  //   const [formSubmitted, setFormSubmitted] = useState(false)
  //   const emailRef = useRef(null)
  //   const handleForm = async e => {
  //     e.preventDefault()
  //     const result = await addToMailchimp(emailRef.current.value)
  //     //setFormError(result.msg)
  //     toast.success(`🌟 ${result.msg}`, {
  //       autoClose: false,
  //     })
  //     setFormSubmitted(true)
  //     console.log("event target", e.target)
  //   }
  return (
    <div>
      {/* {!formSubmitted ? (
        <form onSubmit={handleForm}>
          <Flex sx={{ justifyContent: "center", pt: 2 }}>
            <label className="visuallyhidden" for="email">
              Email Address
            </label>

            <Input
              placeholder="Email Address"
              name="email"
              id="email"
              ref={emailRef}
            />

            <input
              type="submit"
              name="submit"
              sx={{ variant: "buttons.inverted", mt: 0, ml: 2 }}
            />
          </Flex>
        </form>
      ) : (
        <p>Thank you. Please check your email for confirmation.</p>
      )}

      {formError && <p sx={{ color: "white" }}>{formError}</p>}
      <ToastContainer position="bottom-center" /> */}
      <p sx={{ "& a:hover, a:focus-within": { color: "black" } }}>
        To sign up to our email newsletter please{" "}
        <a href="http://eepurl.com/dqjFnf" target="_blank">
          Subscribe
        </a>
        .
      </p>
    </div>
  )
}

export default FooterFormLink
