import { Link } from "gatsby"
import React from "react"

function slugify(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w\-]+/g, "") // Remove all non-word chars
    .replace(/\-\-+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, "") // Trim - from end of text
    .replace(/[_]+/, "-") //Replace underscores with dash
}

const NavDropdown = ({ submenu, hardCodedMenuItems, className }) => {
  return (
    <ul className={`dropdown ${className}`}>
      {hardCodedMenuItems &&
        hardCodedMenuItems.map((navItem, index) => {
          return (
            <li key={index}>
              <Link to={navItem.link_url}>{navItem.link_text}</Link>
            </li>
          )
        })}
      {submenu.map((navItem, index) => {
        let slug = "/#"

        if (navItem.strapi_component === "navigation.nav-custom") {
          //Check its not null
          if (navItem.link_url === null) {
            return (
              <li key={index}>
                <a href={slug}>{navItem.link_text}</a>
              </li>
            )
          }
          //ext link or internal
          else if (navItem.link_url.charAt(0) === "/") {
            slug = navItem.link_url
            return (
              <li key={index}>
                <Link to={slug}>{navItem.link_text}</Link>
              </li>
            )
          } else {
            return (
              <li key={index}>
                <a href={slug}>{navItem.link_text}</a>
              </li>
            )
          }
        }

        if (navItem.strapi_component === "navigation.nav-section") {
          slug = `/${slugify(navItem.page.nav_parent)}/${navItem.page.slug}`
        }
        if (navItem.strapi_component === "navigation.nav-news-item") {
          slug = `/news/${navItem.news_item.slug}`
        }
        if (navItem.strapi_component === "navigation.nav-event") {
          slug = `/event/${navItem.event.slug}`
        }

        return (
          <li key={index}>
            <Link to={slug}>{navItem.link_text}</Link>
          </li>
        )
      })}
    </ul>
  )
}
export default NavDropdown
