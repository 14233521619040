import { Link } from "gatsby"
import React from "react"

function slugify(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w\-]+/g, "") // Remove all non-word chars
    .replace(/\-\-+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, "") // Trim - from end of text
    .replace(/[_]+/, "-") //Replace underscores with dash
}

const FooterLink = ({ item }) => {
  let slug = "/#"

  if (item.strapi_component === "navigation.nav-custom") {
    //Check its not null
    if (item.link_url === null) {
      return <a href={slug}>{item.link_text}</a>
    }
    //ext link or internal
    else if (item.link_url.charAt(0) === "/") {
      slug = item.link_url
      return <Link to={slug}>{item.link_text}</Link>
    } else {
      return <a href={slug}>{item.link_text}</a>
    }
  }

  if (item.strapi_component === "navigation.nav-section") {
    if (item.page.nav_parent === "none") {
      slug = `/${item.page.slug}`
    } else {
      slug = `/${slugify(item.page.nav_parent)}/${item.page.slug}`
    }
  }
  if (item.strapi_component === "navigation.nav-news-item") {
    slug = `/news/${item.news_item.slug}`
  }
  if (item.strapi_component === "navigation.nav-event") {
    slug = `/event/${item.event.slug}`
  }

  return <Link to={slug}>{item.link_text}</Link>
}
export default FooterLink
