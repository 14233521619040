import { Link } from "gatsby"
import React, { useState } from "react"
import {
  FaPiggyBank,
  FaRegEnvelope,
  FaFacebookF,
  FaTwitter,
  FaInstagram,
} from "react-icons/fa"
import { useStaticQuery, graphql } from "gatsby"
/** @jsx jsx */
import { Box, Flex, jsx, Text, Input, Button } from "theme-ui"
import SanitizedHTMLBodyCopy from "./sanitizedHTMLBodyCopy"
import FooterLink from "./footerLink"
//import FooterForm from "./FooterForm"
import FooterFormLink from "./FooterFormLink"

const Footer = () => {
  //static image query
  const data = useStaticQuery(graphql`
    {
      strapiNavigationFooter {
        address
        column1 {
          event {
            slug
          }
          strapi_component
          link_text
          link_url
          news_item {
            slug
          }
          page {
            slug
            nav_parent
          }
        }
        column2 {
          event {
            slug
          }
          strapi_component
          link_text
          link_url
          news_item {
            slug
          }
          page {
            slug
            nav_parent
          }
        }
      }
      strapiContactPage {
        twitter
        facebook
        instagram
      }
      logoFooter: file(relativePath: { eq: "masterclass-logo-white.svg" }) {
        publicURL
      }
    }
  `)

  return (
    <footer
      sx={{
        //background: "#383838",
        background: "rgb(56,56,56)",
        background:
          "linear-gradient(180deg, rgba(56,56,56,1) 60%, rgba(0,0,0,1) 100%)",
        position: "relative",
        color: "white",
        maxWidth: "100vw",
        "& a": {
          color: "white",
          "&:hover": {
            color: "secondary",
          },
        },
        "& hr": {
          background: "muted",
          color: "muted",
        },
      }}
    >
      <Flex
        sx={{
          background:
            "linear-gradient(90deg, rgba(244,5,88,1) 0%, rgba(226,30,54,1) 100%)",
          padding: 4,
          mx: [4, 4, 6],
          display: ["block", "flex", null],
          position: ["relative", "absolute", null],
          top: "-50px",
          "& h1": {
            fontSize: 4,
            mt: 1,
          },
          "& > div": {
            px: 2,
            py: [3, null, null],
          },
        }}
      >
        <Box
          sx={{
            width: ["100%", "33%"],
            a: { textDecoration: "none" },
            "& a:hover, & a:focus-within": {
              color: "black",
            },
          }}
        >
          <Link to="/support-us/sponsorship">
            <Flex>
              <FaPiggyBank sx={{ fontSize: 5, mr: 3 }} />
              <h1>Support Us</h1>
            </Flex>
          </Link>
          <Box>
            <Text>
              We rely on the generous support of individuals and organisations
              to help keep Masterclass FREE for all young people
            </Text>
          </Box>
        </Box>
        <Box
          sx={{
            width: ["100%", "33%"],
            textAlign: ["center", "center", "left"],
          }}
        >
          <h1>Follow us on Social Media</h1>
          <Flex
            sx={{
              justifyContent: ["left", "center"],
              alignItems: "flex-end",
              pt: 3,
              "& a:hover, a:focus-within": {
                color: "black",
              },
            }}
          >
            <a href={data.strapiContactPage.facebook}>
              <span className="visuallyhidden">Facebook</span>
              <FaFacebookF sx={{ fontSize: 5, mr: 3 }} title="Facebook Icon" />
            </a>
            <a href={data.strapiContactPage.twitter}>
              <span className="visuallyhidden">Twitter</span>
              <FaTwitter sx={{ fontSize: 5, mr: 3 }} title="Twitter Icon" />
            </a>
            <a href={data.strapiContactPage.instagram}>
              <span className="visuallyhidden">Instagram</span>
              <FaInstagram sx={{ fontSize: 5, mr: 3 }} title="Instagram Icon" />
            </a>
          </Flex>
        </Box>
        <Box
          sx={{
            width: ["100%", "33%"],
            "& input": {
              border: "2px solid white",
              borderRadius: 0,
            },
            "& input::placeholder": { color: "white", opacity: 0.6 },
          }}
        >
          <Flex>
            <FaRegEnvelope sx={{ fontSize: 5, mr: 3 }} /> <h1>Newsletter</h1>
          </Flex>

          {/* <FooterForm /> */}
          <FooterFormLink />
        </Box>
      </Flex>
      <Flex
        sx={{
          mx: [3, 5, 6],
          my: [3, 4],
          mt: ["-25px", "auto"],
          flexWrap: "wrap",
          pt: [2, "250px"],
          px: [3, 1],
          "& a": {
            display: "block",
            py: [3, 3, 1],
            px: 2,
            lineHeight: [1.2, 1.2, 1.1],
            fontSize: 3,
            ":hover": {
              textDecoration: "none",
              color: "accent",
            },
          },
        }}
      >
        <Box sx={{ width: ["50%", "33%"] }}>
          {data.strapiNavigationFooter.column1.map((item, index) => {
            return <FooterLink key={index} item={item} className="footerLink" />
          })}
        </Box>
        <Box sx={{ width: ["50%", "33%"] }}>
          {data.strapiNavigationFooter.column2.map((item, index) => {
            return <FooterLink key={index} item={item} className="footerLink" />
          })}
        </Box>
        <Box
          sx={{
            width: ["100%", "33%"],
            mt: [3, "-20px", "-85px"],
            fontSize: 3,
            textAlign: ["center", "center", "left"],
            "& h1": {
              color: "white",
              mt: 1,
              mb: 1,
              fontSize: 3,
              fontWeight: 700,
            },
          }}
        >
          <img src={data.logoFooter.publicURL} alt="Masterclass logo" />
          {/* <h1>Masterclass</h1>{" "}
          <p>
            Theatre Royal Haymarket
            <br />
            London
            <br />
            SW1Y 4HT
          </p>
          <p>
            Theatre Royal Haymarket Masterclass Trust is a registered charity
            (no. 1159840)
          </p> */}
          <SanitizedHTMLBodyCopy
            content={data.strapiNavigationFooter.address}
          />
        </Box>
      </Flex>
      <hr sx={{ bg: "muted", height: "1px", border: 0 }} />
      <Flex
        sx={{
          display: ["block", "flex"],
          justifyContent: "space-between",
          textAlign: ["center", "left"],
          mx: ["0 auto", 6],
        }}
      >
        <p>© Masterclass {new Date().getFullYear()}.</p>
        <p>
          Website by <a href="#">Exposure Design</a>
        </p>
      </Flex>
    </footer>
  )
}

export default Footer
